/**
 *  This style sheet contains the custom styling applied
 *  to the course panel
 */

@import 'global/colors.scss';

#course-panel input {
    color: var(--input-text-color);
}

#course-input {
    box-shadow: none;
}

#course-input:focus,
.bootstrap-select button.dropdown-toggle:focus {
    color: var(--input-text-color);
    border-color: var(--input-border-active-color) !important;
    outline: none !important;
    box-shadow: 0 0 0 0.25rem var(--input-border-shadow-color);
}

.bootstrap-select button.dropdown-toggle {
    border-color: var(--input-border-color) !important;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    margin-bottom: 0.3rem;

    &:not(.disabled) {
        background-color: var(--background-color);
    }
}

.bootstrap-select .btn-group {
    width: 100%;
}

#slot-buttons {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    flex-wrap: wrap;
    /* Correcting the .slot-button margin */
    padding-right: calc(var(--bs-gutter-x) * 0.5 - 0.3rem);
    padding-left: calc(var(--bs-gutter-x) * 0.5 - 0.3rem);
}

.slot-button {
    margin: 0.3rem;
    padding: 0.5rem;
    border: 1px solid var(--primary-color);
    border-radius: 0.5rem;
    background: none;
    color: var(--text-color);
    text-transform: uppercase;
    transition: background 0.3s, border-color 0.3s;
}

.slot-button:hover {
    background: var(--slot-button-selected-color);
    border-color: var(--slot-button-selected-color);
}

.slot-button.selected {
    background: var(--slot-button-selected-color);
    border-color: var(--slot-button-selected-color);
}

.slot-button-heading {
    word-break: break-all;
    margin: 5px 0;
}

.slot-button-text {
    margin: 5px 0;
}

@media screen and (max-width: 400px) {
    #slot-buttons {
        grid-template-columns: unset;
    }
}
